import { Injectable } from "@angular/core"

import { HttpService } from "../http.service"
import { FindMovieAttributeDto, MovieAttributeDto, PageResultDto } from "../../dto"

@Injectable()
export class MovieAttributeService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ searchText, limit, offset }: FindMovieAttributeDto) {
    return await this.httpService.get<MovieAttributeDto[]>(`/admin/v1/movie-attributes`, { searchText, limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<MovieAttributeDto>(`/admin/v1/movie-attributes/${id}`)
  }

  async setIcon(id: string, fileId: string) {
    return await this.httpService.patch<boolean>(`/admin/v1/movie-attributes/${id}/icon`, { fileId })
  }

  async setMobileIcon(id: string, fileId: string) {
    return await this.httpService.patch<boolean>(`/admin/v1/movie-attributes/${id}/mobile-icon`, { fileId })
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<boolean>(`/admin/v1/movie-attributes/sort-order`, { ids })
  }
}
