import _ from 'lodash'
import * as moment from 'moment'

import { TranslationDto } from './translation.dto'
import { BaseDto } from './base.dto'
import { FileDto } from './file.dto'
import { PagingDto } from './paging.dto'
import { EventCategoryDto } from './event-category.dto'
import { EventTicketDto } from './event-ticket.dto'

export class EventDto extends BaseDto {
  title: TranslationDto
  endDate: string
  eventImages: FileDto[]
  enabled: boolean
  description: TranslationDto
  mobileImage: FileDto
  location: string
  latitude: number
  longitude: number
  category: EventCategoryDto
  eventCategoryId: string
  recurringType: string
  startDate: string
  startTime: string

  static create({ title, endDate, enabled, eventImages }: EventDto) {
    return _.assign(new EventDto(), {
      title,
      endDate,
      enabled,
      eventImages
    })
  }
}

export class EventDetailDto extends BaseDto {
  title: TranslationDto
  description: TranslationDto
  customLabel: TranslationDto
  mobileImage: FileDto
  location: string
  locationAddress: string
  latitude: number
  longitude: number
  category: EventCategoryDto
  eventCategoryId: string
  recurringType: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  validUntil: string
  eventTickets: EventTicketDto[]
  eventImages: FileDto[]

  static create(event?: EventDetailDto) {
    return _.assign(new EventDetailDto(), {
      id: event?.id ?? null,
      title: event?.title ?? TranslationDto.create(),
      description: event?.description ?? TranslationDto.create(),
      customLabel: event?.customLabel ?? TranslationDto.create(),
      location: event?.location,
      locationAddress: event?.locationAddress,
      latitude: event?.latitude,
      longitude: event?.longitude,
      category: event?.category,
      eventCategoryId: event?.category?.id,
      recurringType: event?.recurringType ?? 'one-time',
      startDate: event?.startDate ? new Date(event.startDate) : null,
      endDate: event?.endDate ? new Date(event.endDate) : null,
      validUntil: event?.validUntil ? new Date(event.validUntil) : null,
      startTime: event?.startDate ? moment(event?.startDate).set({ year: new Date().getFullYear(), month: new Date().getMonth(), date: new Date().getDate() }).toDate() : null,
      endTime: event?.endDate ? moment(event?.endDate).set({ year: new Date().getFullYear(), month: new Date().getMonth(), date: new Date().getDate() }).toDate() : null,      
      mobileImage: event?.mobileImage ?? null,
      eventTickets: event?.eventTickets ?? [],
      eventImages: event?.eventImages ?? []
    })
  }
}

export class CreateEventDto {
  title: TranslationDto
  description: TranslationDto
  customLabel: TranslationDto
  location: string
  locationAddress: string
  latitude: number
  longitude: number
  eventCategoryId: string
  recurringType: string
  startDate?: string
  endDate?: string
  validUntil?: string
  bannerIds: string[]
  mobileImageId: string
}
export class UpdateEventDto {
  title?: TranslationDto
  description?: TranslationDto
  customLabel?: TranslationDto
  location?: string
  locationAddress?: string
  latitude?: number
  longitude?: number
  eventCategoryId?: string
  recurringType?: string
  startDate?: string
  endDate?: string
  validUntil?: string
  bannerIds?: string[]
  mobileImageId?: string
}

export class FindEventDto extends PagingDto {
  searchText?: string
}

