import { Injectable } from "@angular/core";

import { HttpService } from "../http.service";
import { FeaturedEventDto, PageResultDto } from "../../dto";
import { CreateEventDto, EventDetailDto, EventDto, FindEventDto, UpdateEventDto } from "../../dto/event.dto";
import { CreateEventItemDto, SectionEventDto } from "../../dto/featured-event.dto";

@Injectable()
export class EventService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, searchText }: FindEventDto) {
    return await this.httpService.get<PageResultDto<EventDto>>('/admin/v1/events', { searchText, limit, offset })
  }

  async findValid({ limit, offset, searchText }: FindEventDto) {
    return await this.httpService.get<PageResultDto<EventDto>>('/admin/v1/events/validity', { searchText, limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<EventDetailDto>(`/admin/v1/events/${id}`)
  }

  async create(createEventDto: CreateEventDto) {
    return await this.httpService.post<EventDto>('/admin/v1/events', createEventDto)
  }

  async update(id: string, updateEventDto: UpdateEventDto) {
    return await this.httpService.put<boolean>(`/admin/v1/events/${id}`, updateEventDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/events/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/events/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/events/${id}/disable`)
  }

  //////////////////////////////////////////////////////////////////////////
  // Feature Event
  //////////////////////////////////////////////////////////////////////////

  
  async findFeature() {
    return await this.httpService.get<FeaturedEventDto[]>('/admin/v1/events/featured')
  }

  async createFeature({ eventId }: FeaturedEventDto) {
    return await this.httpService.post<Boolean>('/admin/v1/events/featured', { eventId })
  }

  async sortOrder(featureIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/events/featured/reorder`, { featureIds })
  }

  async deleteFeature(featureId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/events/featured/${featureId}`)
  }

  //////////////////////////////////////////////////////////////////////////
  // Section Event
  //////////////////////////////////////////////////////////////////////////
  
  async findSection() {
    return await this.httpService.get<SectionEventDto[]>('/admin/v1/events/section')
  }

  async sortOrderSection(sectionEventIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/events/section/reorder`, { sectionEventIds })
  }

  async findSectionDetail(sectionId: string) {
    return await this.httpService.get<SectionEventDto>(`/admin/v1/events/section/${sectionId}`)
  }

  async createSection(sectionEventDto: SectionEventDto) {
    return await this.httpService.post<SectionEventDto>('/admin/v1/events/section', sectionEventDto)
  }

  async createSectionItem(sectionId: string, createEventItemDto: CreateEventItemDto) {
    return await this.httpService.post<SectionEventDto>(`/admin/v1/events/section/${sectionId}/items`, createEventItemDto)
  }

  async deleteSectionItem(sectionId: string, itemId: string) {
    return await this.httpService.delete<SectionEventDto>(`/admin/v1/events/section/${sectionId}/items/${itemId}`)
  }

  async updateSection(sectionId: string, sectionEventDto: SectionEventDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/events/section/${sectionId}`, sectionEventDto)
  }

  async sortOrderSectionItem(sectionId: string, sectionEventItemIds: string[]) {
    return await this.httpService.post<Boolean>(`/admin/v1/events/section/${sectionId}/items/reorder`, { sectionEventItemIds })
  }

  async deleteSection(eventId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/events/section/${eventId}`)
  }

  async setEnabledSection(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/events/section/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/events/section/${id}/disable`)
  }
}