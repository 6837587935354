import { Injectable } from "@angular/core";

import { CreateAnnouncementNotificationDto, PageResultDto, PagingDto, AnnouncementNotificationDto } from "../../dto";

import { HttpService } from "../http.service";

@Injectable()
export class AnnouncementNotificationService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<AnnouncementNotificationDto>>('/admin/v1/announcement-notification', { limit, offset })
  }

  async create(createAnnouncementNotificationDto: CreateAnnouncementNotificationDto) {
    return await this.httpService.post<AnnouncementNotificationDto>('/admin/v1/announcement-notification', createAnnouncementNotificationDto)
  }
}