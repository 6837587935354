import { BaseDto } from "./base.dto"
import { PagingDto } from "./paging.dto"

export class ScheduledFilmDto extends BaseDto {
  title: string
  rating: string
  ratingDescription: string
  cinemaProvider: string
}

export class FindScheduledFilmDto extends PagingDto {
  searchText?: string
  cinemaProvider?: string
}
