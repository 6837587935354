import { Injectable } from "@angular/core"

import { HttpService } from "../http.service"
import { CreateMovieDto, FindMovieDto, MovieDto, PageResultDto, ScheduledFilmDto, UpdateMovieDto } from "@api/dto"


@Injectable()
export class MovieService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, searchText }: FindMovieDto) {
    return await this.httpService.get<PageResultDto<MovieDto>>('/admin/v1/movies', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<MovieDto>(`/admin/v1/movies/${id}`)
  }

  async create(createMovieDto: CreateMovieDto) {
    return await this.httpService.post<MovieDto>('/admin/v1/movies', createMovieDto)
  }

  async update(id: string, updateMovieDto: UpdateMovieDto) {
    return await this.httpService.put<boolean>(`/admin/v1/movies/${id}`, updateMovieDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/movies/${id}`)
  }

  async link(id: string, scheduledFilmIds: string[]) {
    return await this.httpService.post<Boolean>(`/admin/v1/movies/${id}/scheduled-films`, { scheduledFilmIds })
  }

  async findLink(id: string) {
    return await this.httpService.get<ScheduledFilmDto[]>(`/admin/v1/movies/${id}/scheduled-films`)
  }
}
