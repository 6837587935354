import * as _ from 'lodash'

import { FileDto } from './file.dto'
import { TranslationDto } from './translation.dto'

export class PageDto {
  id: string
  title: TranslationDto
  content: TranslationDto
  image: FileDto
  imageId?: string
  slug: string

  static create() {
    const page = new PageDto()

    page.title = TranslationDto.create()
    page.content = TranslationDto.create()

    return page
  }
}
