import { Injectable } from "@angular/core";

import { HttpService } from "../http.service";
import { AllowPhoneNumberDto, CreateAllowPhoneNumberDto, FindAllowPhoneNumberDto, PageResultDto, UpdateAllowPhoneNumberDto } from "@api/dto";

@Injectable()
export class AllowPhoneNumberService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset }: FindAllowPhoneNumberDto) {
    return await this.httpService.get<PageResultDto<AllowPhoneNumberDto>>('/admin/v1/allow-phone-numbers', { limit, offset })
  }

  async create(createAllowPhoneNumberDto: CreateAllowPhoneNumberDto) {
    return await this.httpService.post<AllowPhoneNumberDto>(`/admin/v1/allow-phone-numbers`, createAllowPhoneNumberDto)
  }

  async update(id: string, updateAllowPhoneNumberDto: UpdateAllowPhoneNumberDto) {
    return await this.httpService.put<boolean>(`/admin/v1/allow-phone-numbers/${id}`, updateAllowPhoneNumberDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/allow-phone-numbers/${id}`)
  }
}