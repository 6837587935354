import { Injectable } from "@angular/core"

import { HttpService } from "../http.service"
import { FindScheduledFilmDto, PageResultDto, PagingDto, ScheduledFilmDto } from "@api/dto"


@Injectable()
export class ScheduledFilmService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, searchText, cinemaProvider }: FindScheduledFilmDto) {
    return await this.httpService.get<PageResultDto<ScheduledFilmDto>>('/admin/v1/scheduled-films', { limit, offset, searchText, cinemaProvider })
  }

  async findOne(id: string) {
    return await this.httpService.get<ScheduledFilmDto>(`/admin/v1/scheduled-films/${id}`)
  }
}
