import { BaseDto } from "./base.dto"

import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"

export class MovieAttributeDto extends BaseDto {
  showAsTag: boolean
  shortName: string
  description: string
  icon: FileDto
  mobileIcon: FileDto
  cinemaProviderAttributeId: string
  cinemaProvider: string
  sortOrder: number

  static create() {
    return new MovieAttributeDto()
  }
}

export class FindMovieAttributeDto extends PagingDto {
  searchText?: string
  cinemaProvider?: string
}
