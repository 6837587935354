import _ from 'lodash'

import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from './translation.dto'
import { DateDto } from './date.dto'
import { ScheduledFilmDto } from './scheduled-film.dto'

export class MovieDto extends BaseDto {
  title?: TranslationDto
  description?: TranslationDto
  genreName?: TranslationDto
  synopsis?: TranslationDto
  rating?: TranslationDto
  runTime?: number
  openingDate?: DateDto
  websiteUrl?: string
  trailerUrl?: string
  twitterTag?: string
  distributor?: string
  hoCode?: string
  hopk?: string
  isAdvancedTicket?: boolean
  bannerImage?: FileDto
  mobileBannerImage?: FileDto
  backdropImage?: FileDto
  mobileBackdropImage?: FileDto
  ageRatingImage?: FileDto
  mobileAgeRatingImage?: FileDto
  enabled?: boolean
  cinemaProviders?: string[]

  static create({ id, title, description, rating, synopsis, genreName, enabled, openingDate, bannerImage, ageRatingImage, backdropImage, mobileBannerImage, mobileBackdropImage, mobileAgeRatingImage }: MovieDto) {
    return _.assign<MovieDto>({
      id: id || null,
      title: title || TranslationDto.create(),
      description: description || TranslationDto.create(),
      rating: rating || TranslationDto.create(),
      synopsis: synopsis || TranslationDto.create(),
      genreName: genreName || TranslationDto.create(),
      enabled: enabled,
      openingDate: openingDate,
      bannerImage: bannerImage || null,
      ageRatingImage: ageRatingImage || null,
      backdropImage: backdropImage || null,
      mobileBannerImage: mobileBannerImage || null,
      mobileBackdropImage: mobileBackdropImage || null,
      mobileAgeRatingImage: mobileAgeRatingImage || null
    })
  }
}

export class CreateMovieDto {
  title: TranslationDto
  // description: TranslationDto
  genreName: TranslationDto
  synopsis: TranslationDto
  rating: TranslationDto

  runTime: number
  openingDate: DateDto
  // websiteUrl: string
  trailerUrl: string
  // twitterTag: string
  distributor: string
  // hoCode: string
  // hopk: string
  isAdvancedTicket: boolean
  
  bannerImageId: string
  backdropImageId: string
  ageRatingImageId?: string
  mobileBannerImageId: string
  mobileBackdropImageId: string
  mobileAgeRatingImageId?: string
}

export class UpdateMovieDto {
  title?: TranslationDto
  // description?: TranslationDto
  genreName?: TranslationDto
  synopsis?: TranslationDto
  rating?: TranslationDto

  runTime?: number
  openingDate?: DateDto
  // websiteUrl?: string
  trailerUrl?: string
  // twitterTag?: string
  distributor?: string
  // hoCode?: string
  // hopk?: string
  isAdvancedTicket?: boolean

  bannerImageId?: string
  backdropImageId?: string
  ageRatingImageId?: string
  mobileBannerImageId?: string
  mobileBackdropImageId?: string
  mobileAgeRatingImageId?: string

  enabled?: boolean
}

export class FindMovieDto extends PagingDto {
  searchText?: string
}

export class FilmSessionDto extends BaseDto {
  attributes: any[]
  startTime: Date
  endTime: Date
  screenName: string
  screenNumber: number
  cinemaOperatorCode: string
  sessionBusinessDate: Date
  formatCode: string
  formatName: string
  areaCategoryCodes: string[]
  attribute: any
  cinemaProviderSessionId: string
  cinemaProviderCinemaId: string
  cinemaProvider: string
  scheduledFilmId: string
  scheduledFilm: ScheduledFilmDto
}