import { BaseDto } from "./base.dto";
import { FileDto } from "./file.dto";
import _ from 'lodash'
import { TranslationDto } from "./translation.dto";
import { PagingDto } from "./paging.dto";

export class WelcomeBannerDto extends BaseDto {
  title: TranslationDto
  mobileImage: FileDto
  enabled: boolean

  static create(welcomeBanner?: WelcomeBannerDto) {
    return _.assign<WelcomeBannerDto>({
      id: welcomeBanner?.id ?? null,
      title: welcomeBanner.title || TranslationDto.create(),
      mobileImage: welcomeBanner.mobileImage,
      enabled: welcomeBanner.enabled
    })
  }
}

export class WelcomeBannerDetailDto extends BaseDto {
  title: TranslationDto
  description: TranslationDto
  mobileImage: FileDto

  static create(welcomeBanner?: WelcomeBannerDetailDto) {
    return _.assign(new WelcomeBannerDetailDto(), {
      id: welcomeBanner?.id ?? null,
      title: welcomeBanner?.title ?? TranslationDto.create(),
      description: welcomeBanner?.description ?? TranslationDto.create(),
      mobileImage: welcomeBanner?.mobileImage ?? null
    })
  }
}

export class CreateWelcomeBannerDto {
  title: TranslationDto
  description: TranslationDto
  mobileImageId: string
  type: string
}

export class UpdateWelcomeBannerDto {
  title?: TranslationDto
  description?: TranslationDto
  mobileImageId?: string
}

export class FindWelcomeBannerDto extends PagingDto {
  searchText?: string
  type: string
}