import { Injectable } from '@angular/core'

import { PageResultDto, FindVoucherDto, VoucherGroupDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class VoucherService {
  constructor(
    private httpService: HttpService) {
  }

  async create(voucherDto: VoucherGroupDto) {
    return this.httpService.post<VoucherGroupDto>('/admin/v1/vouchers', voucherDto)
  }v

  async find(findVoucherDto: FindVoucherDto) {
    return await this.httpService.get<PageResultDto<VoucherGroupDto>>('/admin/v1/vouchers', findVoucherDto)
  }

  async findOne(id: string) {
    return this.httpService.get<VoucherGroupDto>(`/admin/v1/vouchers/${id}`)
  }

  async exportExcelVouchers(id: string) {
    return await this.httpService.get<any>(`/admin/v1/vouchers/${id}/export-excel`)
  }

  async destroy(id: string) {
    return this.httpService.delete<boolean>(`/admin/v1/vouchers/${id}`)
  }
}
