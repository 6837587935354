import { BaseDto } from "./base.dto";
import { FileDto } from "./file.dto";

export class CinemaProviderDto extends BaseDto {
  code: string
  name: string
  sortOrder: number
  enabled: boolean
  image: FileDto
  mobileImage: FileDto

  static create() {
    return new CinemaProviderDto()
  }
}

export class UpdateCinemaProviderDto {
  imageId?: string
  mobileImageId?: string
  name?: string
  enabled?: boolean
}

export enum CinemaProviderCode {
  LEGEND = 'legend'
}