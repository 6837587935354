import { Injectable } from '@angular/core'

import _ from 'lodash'

import { HttpService } from '../http.service'
import { ConcessionDto, FindConcessionDto, PageResultDto, UpdateConcessionDto } from '../../dto'

@Injectable()
export class ConcessionService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ searchText, limit, offset }: FindConcessionDto) {
    return await this.httpService.get<PageResultDto<ConcessionDto>>(`/admin/v1/concessions`, { searchText, limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<ConcessionDto>(`/admin/v1/concessions/${id}`)
  }

  async setBannerImage(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/concessions/${id}/banner-image`, { fileId: iconId })
  }

  async setMobileBannerImage(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/concessions/${id}/mobile-banner-image`, { fileId: iconId })
  }

  async update(id: string, updateConcessionDto: UpdateConcessionDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/concessions/${id}`, updateConcessionDto)
  }
}
