import { BaseDto } from "./base.dto";
import _ from 'lodash'
import { TranslationDto } from "./translation.dto";
import { UserDto } from "./user.dto";

export class AnnouncementNotificationDto extends BaseDto {
  title?: TranslationDto;
  message?: TranslationDto;
  creator: UserDto;

  static create({ title, message, creator }: Partial<AnnouncementNotificationDto> = {}) {
    return _.assign<AnnouncementNotificationDto>(
      {
        title: title || TranslationDto.create(),
        message: message || TranslationDto.create(),
        creator: creator ? UserDto.create() : null,
      }
    );
  }
}

export class CreateAnnouncementNotificationDto {
  title: TranslationDto
  message: TranslationDto
}