import { BaseDto } from "./base.dto"

import { CinemaProviderDto } from "./cinema-provider.dto"

import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"

export class CinemaDto extends BaseDto {
  phoneNumber: string
  name: string
  address: string
  emailAddress: string
  cinemaProviderCinemaId: string
  openHour: string
  closeHour: string
  city: string
  latitude: number
  longitude: number
  cinemaProvider: CinemaProviderDto
  bannerImage: FileDto
  mobileBannerImage: FileDto
  image: FileDto
  mobileImage: FileDto
  enabled: boolean

  static create() {
    return new CinemaDto()
  }
}

export class UpdateCinemaDto {
  imageId?: string
  mobileImageId?: string
  bannerImageId?: string
  mobileBannerImageId?: string
  phoneNumber?: string
  name?: string
  address?: string
  emailAddress?: string
  cinemaProviderCinemaId?: string
  openHour?: string
  closeHour?: string
  city?: string
  latitude?: number
  longitude?: number
  enabled?: boolean
}

export class FindCinemaDto extends PagingDto {
  cinemaProviderId?: string
}