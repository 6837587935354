import { Injectable } from "@angular/core"

import { HttpService } from "../http.service"
import { CinemaProviderDto, PageResultDto, PagingDto, UpdateCinemaProviderDto } from "@api/dto"


@Injectable()
export class CinemaProviderService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<CinemaProviderDto>>('/admin/v1/cinema-providers', { limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<CinemaProviderDto>(`/admin/v1/cinema-providers/${id}`)
  }

  async update(id: string, updateCinemaProviderDto: UpdateCinemaProviderDto) {
    return await this.httpService.put<boolean>(`/admin/v1/cinema-providers/${id}`, updateCinemaProviderDto)
  }
}
