import { BaseDto } from "./base.dto";
import { FileDto } from "./file.dto";
import { PagingDto } from "./paging.dto";
import _ from 'lodash'

export class BannerDto extends BaseDto {
  link: string
  mobileImage: FileDto
  websiteImage: FileDto
  enabled: boolean

  static create(banner?: BannerDto) {
    return _.assign<BannerDto>({
      id: banner?.id ?? null,
      link: banner?.link,
      mobileImage: banner?.mobileImage,
      websiteImage: banner?.websiteImage
    })
  }
}

export class CreateBannerDto {
  link: string
  mobileImageId: string
  websiteImageId: string
  type: string
}

export class UpdateBannerDto {
  link?: string
  mobileImageId?: string
  websiteImageId?: string
}

export class FindBannerDto extends PagingDto {
  searchText?: string
  type: string
}