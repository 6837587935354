import { Injectable } from '@angular/core'

import { SettingDto } from '../../dto'
import { HttpService } from '../http.service'


@Injectable()
export class SettingService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<SettingDto[]>('/admin/v1/setting')
  }

  async update(pageId: string, settingDto: SettingDto) {
    return await this.httpService.put<SettingDto>(`/admin/v1/setting/${pageId}`, settingDto)
  }
}
