import { BaseDto } from './base.dto'

export class SettingDto extends BaseDto {
  id: string
  key: string
  type: string
  value: string | number | boolean

  static create() {
    return new SettingDto()
  }
}