import * as _ from 'lodash'

import { EventDto } from './event.dto'
import { BaseDto } from './base.dto'
import { TranslationDto } from './translation.dto'

export class FeaturedEventDto extends BaseDto{
  eventId: string
  enabled?: boolean
  sortOrder?: number
  event?: EventDto
}

export class CreateEventItemDto {
  eventId: string
}

export class SectionEventItemDto extends BaseDto {
  eventId: string
  sortOrder?: number
  event?: EventDto
}

export class SectionEventDto extends BaseDto{
  title: TranslationDto
  items: SectionEventItemDto[]
  enabled?: boolean
  sortOrder?: number
  event?: EventDto

  static create(event?: SectionEventDto) {
    return _.assign(new SectionEventDto(), {
      id: event?.id ?? null,
      sortOrder: event?.sortOrder,
      enabled: event?.enabled,
      title: event?.title ?? TranslationDto.create(),
      items: event?.items ?? []
    })
  }
}
