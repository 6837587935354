import { BaseDto } from "./base.dto";
import { PagingDto } from "./paging.dto";
import _ from 'lodash'
import { UserDto } from "./user.dto";

export class OrderEventDto extends BaseDto {
  orderNumber: string
  owner: UserDto
  customerName: string
  customerEmail: string
  customerPhone: string
  status: string
  claimStatus: string
  paymentStatus: string
  paymentMethod: string
  total: number

  static create(orderEvent?: OrderEventDto) {
    return _.assign(new OrderEventDto(), {
      id: orderEvent?.id ?? null,
      orderNumber: orderEvent?.orderNumber,
      owner: orderEvent?.owner,
      customerName: orderEvent?.customerName,
      customerEmail: orderEvent?.customerEmail,
      customerPhone: orderEvent?.customerPhone,
      status: orderEvent?.status,
      claimStatus: orderEvent?.claimStatus,
      paymentStatus: orderEvent?.paymentStatus,
      paymentMethod: orderEvent?.paymentMethod,
      total: orderEvent?.total
    })
  }
}
export class OrderEventItemDto {
  name: string
  eventTicketId: string
  ticketCode: string
  quantity: number
  price: number
  total: number
}

export class OrderEventDetailDto extends BaseDto {
  orderNumber: string
  owner: UserDto
  customerName: string
  customerEmail: string
  customerPhone: string
  status: string
  claimStatus: string
  paymentStatus: string
  paymentMethod: string
  paymentData: any
  items: OrderEventItemDto[]
  discountPercentage: number
  discountTotal: number
  subTotal: number
  total: number

  static create(orderEventDetail?: OrderEventDetailDto) {
    return _.assign(new OrderEventDetailDto(), {
      id: orderEventDetail?.id ?? null,
      orderNumber: orderEventDetail?.orderNumber,
      owner: orderEventDetail?.owner,
      customerName: orderEventDetail?.customerName,
      customerEmail: orderEventDetail?.customerEmail,
      customerPhone: orderEventDetail?.customerPhone,
      status: orderEventDetail?.status,
      claimStatus: orderEventDetail?.claimStatus,
      paymentStatus: orderEventDetail?.paymentStatus,
      paymentMethod: orderEventDetail?.paymentMethod,
      paymentData: orderEventDetail?.paymentData,
      items: orderEventDetail?.items,
      discountPercentage: orderEventDetail?.discountPercentage,
      discountTotal: orderEventDetail?.discountTotal,
      subTotal: orderEventDetail?.subTotal,
      total: orderEventDetail?.total
    })
  }
}

export class FindOrderEventDto extends PagingDto {
  searchText?: string
  channel?: string
  recurringType?: string
  startDate?: Date
  endDate?: Date
}

export class FindOrderEventDateFilterDto {
  startDate?: Date
  endDate?: Date
  channel?: string
  recurringType?: string
}