import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { CreateEventCategoryDto, EventCategoryDto, FindEventCategoryDto, PageResultDto, PagingDto, UpdateEventCategoryDto } from "../../dto";

@Injectable()
export class EventCategoryService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, searchText }: FindEventCategoryDto) {
    return await this.httpService.get<PageResultDto<EventCategoryDto>>('/admin/v1/event-category', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<EventCategoryDto>(`/admin/v1/event-category/${id}`)
  }

  async create(createEventCategoryDto: CreateEventCategoryDto) {
    return await this.httpService.post<EventCategoryDto>('/admin/v1/event-category', createEventCategoryDto)
  }

  async update(id: string, updateEventCategoryDto: UpdateEventCategoryDto) {
    return await this.httpService.put<boolean>(`/admin/v1/event-category/${id}`, updateEventCategoryDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/event-category/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/event-category/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/event-category/${id}/disable`)
  }
}