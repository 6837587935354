import { FileDto } from './file.dto'
import * as _ from 'lodash'

export class ProfileDto {
  fullName: string
  email?: string
  photo: FileDto
  roleNames: string[]
  id: string

  static createForm({ fullName, email, photo }: ProfileDto) {
    return _.assign<ProfileDto>({
      fullName: fullName && fullName.length ? fullName : '',
      email,
      photo,
    })
  }

  static create() {
    return Object.assign(new ProfileDto())
  }

}

import { assign } from 'lodash'

export class UpdateProfileDto {
  fullName: string

  static create({fullName}: ProfileDto ) {
    return assign<UpdateProfileDto>({
      fullName
    })
  }
}
