import { FileDto } from "./file.dto"

export class PaymentMethodDto {
  id: string
  name: string
  image: FileDto
  type: string
}

export class PaymentDto {
  id: string
  transactionId: string
  status: string
  paymentMethod: PaymentMethodDto
}