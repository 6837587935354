import _ from "lodash";

import { BaseDto } from "./base.dto";
import { PagingDto } from "./paging.dto";

export class AllowPhoneNumberDto extends BaseDto {
  phoneNumber: string
  otpCode: string

  static create(allowPhoneNumber?: AllowPhoneNumberDto) {
    return _.assign<AllowPhoneNumberDto>({
      id: allowPhoneNumber?.id ?? null,
      phoneNumber: allowPhoneNumber?.phoneNumber,
      otpCode: allowPhoneNumber?.otpCode
    })
  }
}

export class FindAllowPhoneNumberDto extends PagingDto {
  searchText?: string
}

export class CreateAllowPhoneNumberDto {
  phoneNumber: string
  otpCode: string
}

export class UpdateAllowPhoneNumberDto {
  phoneNumber: string
  otpCode: string
}