import _ from 'lodash'

import { BaseDto } from "./base.dto"
import { TranslationDto } from "./translation.dto"
import { FileDto } from './file.dto'

export class EventTicketDto extends BaseDto {
  title: TranslationDto
  description: TranslationDto
  thumbnailImage: FileDto
  bannerImage: FileDto
  price: number
  stock: number
  limit: number
  index?: number

  static create(event?: EventTicketDto) {
    return _.assign(new EventTicketDto(), {
      title: event?.title ?? TranslationDto.create(),
      description: event?.description ?? TranslationDto.create(),
      thumbnailImage: event?.thumbnailImage ?? null,
      bannerImage: event?.bannerImage ?? null,
      price: event?.price ?? null,
      stock: event?.stock ?? null,
      limit: event?.limit ?? null,
    })
  }
}

export class CreateEventTicketDto {
  title: TranslationDto
  description: TranslationDto
  thumbnailId: string
  bannerId: string
  price: number
  stock: number
  limit: number
}
export class UpdateEventTicketDto {
  title?: TranslationDto
  description?: TranslationDto
  thumbnailId?: string
  bannerId?: string
  price?: number
  stock?: number
  limit?: number
}