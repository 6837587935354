import { Injectable } from "@angular/core"

import { HttpService } from "../http.service"
import { CinemaDto, FindCinemaDto, PageResultDto, UpdateCinemaDto } from "@api/dto"


@Injectable()
export class CinemaService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, cinemaProviderId }: FindCinemaDto) {
    return await this.httpService.get<PageResultDto<CinemaDto>>('/admin/v1/cinemas', { limit, offset, cinemaProviderId })
  }

  async findOne(id: string) {
    return await this.httpService.get<CinemaDto>(`/admin/v1/cinemas/${id}`)
  }

  async update(id: string, updateCinemaDto: UpdateCinemaDto) {
    return await this.httpService.put<boolean>(`/admin/v1/cinemas/${id}`, updateCinemaDto)
  }
}
