import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from "./translation.dto"

export class FindConcessionDto extends PagingDto {
  searchText?: string
}

export class ConcessionDto extends BaseDto {
  cinemaProviderConcessionId: string
  description: TranslationDto
  title: TranslationDto
  shortName: string
  mobileBannerImage: FileDto
  bannerImage: FileDto
  price: number
  cinemaProviderCinemaId: string
  cinemaProvider: string

  static create() {
    return new ConcessionDto()
  }
}

export class UpdateConcessionDto {
  description?: TranslationDto
  title?: TranslationDto
}
