import { BaseDto } from "./base.dto";
import _ from 'lodash'
import { TranslationDto } from "./translation.dto";
import { PagingDto } from "./paging.dto";

export class EventCategoryDto extends BaseDto {
  title: TranslationDto

  static create(data?: EventCategoryDto) {
    return _.assign<EventCategoryDto>({
      id: data?.id ?? null,
      title: data?.title || TranslationDto.create(),
    })
  }
}

export class CreateEventCategoryDto {
  title: TranslationDto
}

export class UpdateEventCategoryDto {
  title?: TranslationDto
}

export class FindEventCategoryDto extends PagingDto {
  searchText?: string
}