import { Injectable } from "@angular/core"

import { HttpService } from "../http.service"
import { BannerDto, CreateWelcomeBannerDto, FindWelcomeBannerDto, PageResultDto, PagingDto, UpdateBannerDto, UpdateWelcomeBannerDto, WelcomeBannerDetailDto, WelcomeBannerDto } from "@api/dto"


@Injectable()
export class WelcomeBannerService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, type }: FindWelcomeBannerDto) {
    return await this.httpService.get<PageResultDto<WelcomeBannerDto>>('/admin/v1/welcome-banner', { limit, offset, type })
  }

  async findOne(id: string) {
    return await this.httpService.get<WelcomeBannerDetailDto>(`/admin/v1/welcome-banner/${id}`)
  }

  async create(createWelcomeBannerDto: CreateWelcomeBannerDto) {
    return await this.httpService.post<BannerDto>('/admin/v1/welcome-banner', createWelcomeBannerDto)
  }

  async update(id: string, updateWelcomeBannerDto: UpdateWelcomeBannerDto) {
    return await this.httpService.put<boolean>(`/admin/v1/welcome-banner/${id}`, updateWelcomeBannerDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/welcome-banner/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/welcome-banner/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/welcome-banner/${id}/disable`)
  }
}