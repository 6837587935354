import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { CreateEventTicketDto, EventTicketDto, UpdateEventTicketDto } from "../../dto/event-ticket.dto";

@Injectable()
export class EventTicketService {
  constructor(
    private httpService: HttpService) {
  }

  async create(id: string, createEventTicketDto: CreateEventTicketDto) {
    return await this.httpService.post<boolean>(`/admin/v1/event-ticket/${id}`, createEventTicketDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<EventTicketDto>(`/admin/v1/event-ticket/${id}`)
  }

  async update(id: string, updatePackageDto: UpdateEventTicketDto) {
    return await this.httpService.put<boolean>(`/admin/v1/event-ticket/${id}`, updatePackageDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/event-ticket/${id}`)
  }
}