import { Injectable } from '@angular/core'

import { UserDto, PageResultDto, FindUserDto, CreateUserDto, UpdateUserDto, UserDetailDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class UserService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createUserDto: CreateUserDto) {
    return this.httpService.post<UserDto>('/admin/v1/users/', createUserDto)
  }

  async find(findUserDto: FindUserDto) {
    return await this.httpService.get<PageResultDto<UserDto>>('/admin/v1/users', findUserDto)
  }

  async findOne(id: string) {
    return this.httpService.get<UserDetailDto>(`/admin/v1/users/${id}`)
  }

  async update(id: string, updateUserDto: UpdateUserDto) {
    return this.httpService.put<UserDto>(`/admin/v1/users/${id}`, updateUserDto)
  }

  async destroy(id: string) {
    return this.httpService.delete<boolean>(`/admin/v1/users/${id}`)
  }

  async updatePassword(id: string, password: string) {
    return this.httpService.post<UserDto>(`/admin/v1/users/${id}/password`, { password })
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/v1/users/${id}/enable`)
    }

    return await this.httpService.put(`/admin/v1/users/${id}/disable`)
  }
}
