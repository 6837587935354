import { Injectable } from '@angular/core'


import { HttpService } from '../http.service'
import { ExportOrderResultDto, FindOrderDateFilterDto, FindOrderDto, OrderDto, PageResultDto } from '../../dto'

@Injectable()
export class OrderService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/admin/v1/orders', findOrderDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<OrderDto>(`/admin/v1/orders/${id}`)
  }

  async exportExcel(dateFilterDto: FindOrderDateFilterDto) {
    return await this.httpService.get<ExportOrderResultDto>(`/admin/orders/v1/export`, dateFilterDto)
  }
}
